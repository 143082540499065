@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@700&display=swap');


* {
    font-family: 'Merienda', cursive;
    /* font-family: 'Courgette', cursive; */
    /* font-family: 'Inter'; */
}

:root {
    --main-color: #01545A;
    --sub-color: #888;
    --light: #fff;
    --dark: #000;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    /* border-radius: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 5px;
}

a {
    color: var(--dark);
    text-decoration: none;
}

a:hover {
    color: var(--dark);
}

.btn-style {}

.text-light {
    color: var(--light) !important;
}

.text-light:hover {
    color: var(--light) !important;
}

.text-main {
    color: var(--main-color) !important;
}

.bg-main {
    background-color: var(--main-color) !important;
}

.border-main {
    border-bottom: 2px solid var(--main-color) !important;
}

.hover-main {
    box-shadow: 0px 0px 5px 5px var(--main-color) !important;
}

.main-shadow:hover {
    box-shadow: 0px 0px 1px 1px var(--main-color) !important;
}

.text-sub {
    color: var(--sub-color) !important;
}

.bg-sub {
    background-color: var(--sub-color) !important;
}

.border-sub {
    border-bottom: 2px solid var(--sub-color) !important;
}

.hover-sub {
    box-shadow: 0px 0px 5px 5px var(--sub-color) !important;
}

.sub-shadow:hover {
    box-shadow: 0px 0px 1px 1px var(--sub-color) !important;
}

.left-navbar-links {
    position: relative;
    height: 100%;
}

.left-navbar-links li.logout-link {
    position: absolute;
    bottom: 0;
}

.bg-light {
    background-color: var(--light) !important;
}

.text-dark {
    color: #000;
}

.slidpar-header * {
    color: #000;
}

.blue_gradent {
    background-color: #007bff;
    background-image: linear-gradient(45deg, #007bff 0%, #4d94ff 100%);
}

.purple_gradent {
    background-color: #8a2be2;
    background-image: linear-gradient(45deg, #8a2be2 0%, #cda1ff 100%);
}

.orange_gradent {
    background-color: #ff6600;
    background-image: linear-gradient(45deg, #ff6600 0%, #ffaa4d 100%);
}

.green_gradent {
    background-color: #00cc00;
    background-image: linear-gradient(45deg, #00cc00 0%, #a3ff8c 100%);
}

.red_gradent {
    background-color: #ff1493;
    background-image: linear-gradient(45deg, #ff1493 0%, #ffb6c1 100%);
}